.motto {
    content: '';
    background: url('./images/motto.jpeg') no-repeat center center/cover;
    margin: auto;
    padding: 10rem 1rem 10rem  1rem;
    width: 100%;
}

.motto .main-content{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.motto .main-content-box{
    max-width: 720px;
    background-color: var(--gray);
    font: var(--font_italic);
    padding: 3rem;
    margin-right: 3rem;
    font-size: 1.5rem;   
}

@media screen and (max-width:940px) {
    .motto .container {
        grid-template-columns: 1fr;
    }

    .motto .container {
        width: 100%;
    }

    .motto .container img {
        padding: 0rem;
        width: 90vw;
    }

    .motto .main-content-box {
        padding: 3rem;
        margin: 0rem;
    }

}









