.footer {
  width: 100%;
  /* background-color: #000;*/

  background-color: rgba(174, 174, 174, 0.777); /*cor cinza igual insta*/
  color: #eee;
  padding: 3rem 1rem;
}

.footer p {
  margin-top: 20px;
}

/* .footer a,
.footer a:visited,
.footer a:hover{
    color: #000;
} */

.footer .container {
  max-width: 1240px;
  margin: auto;
  text-align: center;
}

.footer ul {
  display: flex;
  justify-content: center;
}

.footer li {
  padding: 1rem;
}

.footer li a {
  color: #fff;
  cursor: pointer;
}

.footer li a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.bottom {
  text-align: center;
  padding-top: 3rem;
}

.line {
  margin-bottom: 1rem;
}

.footer-content {
  background-color: #f8f8f8; /*var(--gray);*/
  margin-top: 3rem;
  padding: 5rem 1rem 5rem 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  color: #000;
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
}

.footer-content-column {
  width: 100%;
}

@media (max-width: 600px) {
  .footer-content {
    display: flex;
    justify-content: center;
    color: #000;
    width: 100%;
    flex-wrap: wrap;
    padding: 2rem;
  }
  .footer-content-column {
    margin-top: 2rem;
  }
}
