.about{
   
        background-color: var(--white);
        margin: auto;
        padding: 0rem 0rem ;
        width: 100%;
}

.about .title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    font-size: 1.9rem;  
}
.about .subtitle{
    text-align: center;;
    padding: 3rem;
    line-height: 32px;  width: 100%;
 
}

.about .container{
 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3rem;
    /* font: var(--font_italic); */
    font-size: 1.6rem;  
    text-align: center;;
}

.about .section{
    /* background-color: var(--gray); */
    display: grid;    
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: space-evenly;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
    margin: 3rem 0;
    padding:  6rem 2rem;
}

.about .card {
    position: relative;
    min-height: 448px;
}

.card-bottom{
    position: absolute;
    background-color: var(--white); 
    border-radius: 0px; 
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    padding: 1rem;
 
    margin-top: -3rem;
    margin-left: 3rem;   margin-bottom: 3rem;
    z-index: 0;
    width: 286px;
}
.about img {
    border-radius: 0px;
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    width: 286px;
    height: 335px;
    object-fit: cover;
    object-position: 50% 50%;
}


.about .card p {
    color: #787878;
    font-size: 1.1rem;
    margin: 12px;
}

.about .card span {
    color: var(--secondary-color);
    font-size: 1rem;
    font-weight: 700;
}

@media screen and (max-width: 1440px) {
    .about .container {
        width: 100%;
    }

    .about .section {
        grid-template-columns: repeat(3,1fr);
    }

    .about .card {
        max-width: 100%;
        margin: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .about .container {
       
    }

    .about .section {
        grid-template-columns: repeat(2,1fr);
    }

    .about .card {
        max-width: 100%;
        margin: 1rem;
    }
}
@media screen and (max-width: 800px) {
    .about .container {
       
    }

    .about .section {
        grid-template-columns: repeat(1,1fr);
    }

    .about .card {
        max-width: 100%;
        margin: 1rem;
    }
    .about .title{
        text-align: center;font-size: 1.8rem;  
    }
    .about .subtitle{
        text-align: center;
     
    }
}