
 
.portfolio{
    /*background-color: var(--gray);*/
    margin: auto;
   /* padding: 10rem 1rem 10rem  1rem;*/
    width: 100%;
}


.figure
{
    cursor: pointer;
}