.testimonials{
    background-color: var(--secondary-color);
    margin: auto;
    padding: 10rem 1rem 10rem  1rem;
}

.testimonials .title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    font-size: 1.9rem;  
}