.budget {
    background-color: #f8f8f8;
    margin: auto;
    padding: 0rem;
    width: 100%;
}
.budget .title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    font-size: 1.9rem;  
    }
    
.budget .subtitle{
    
    text-align: center;;
    padding: 3rem;
    line-height: 32px;  
    width: 100%;
    }
        
.budget .container {
    
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:2rem;padding: 2rem;
}
.budget .container img{
    max-width: 300px;
 
}
 

.budget .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

/* .budget .col-2 p:first-of-type {
    margin: 2rem 0;
}

.budget .col-2 p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
} */

.budget button {
    margin-top: 1rem;
}

button:disabled,
button:disabled:hover{
    filter: opacity(0.5);
    color: #fff;
}

@media screen and (max-width:940px) {
    .budget .container {
        grid-template-columns: 1fr;
        gap:2rem;
        padding: 2rem;
    }

    .budget .container {
        width: 100%;
    }

    .budget .container img {
        padding: 0rem;
        
        max-width: 210px;
    }

    .budget .container .col-2 {
        padding: 0 ;
        }

    .budget button {
        margin-bottom: 1rem;
    }
    .budget .title{
        text-align: center;font-size: 1.8rem;    
    }
    .budget .subtitle{
       text-align: center;
       margin: auto;
       padding: 0 2rem;
    }

    .budget .col-2 {
           margin-top: 2rem;
    }

}
/* 

form{ 
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    font-size: .9rem;
    margin-bottom: 10px;
    padding: 6px 10px;
    width: 100%;
}

input, textarea{
    display: block;
    width: 100%;
    padding: .5rem .8rem .5rem .8rem;
    margin: 1vw 0 ;
    border:0;
    border-radius: 5px;
    font-size: 20px;
}

 textarea{
    height: 15vh;
}

 */

 